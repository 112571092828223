<template>
  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'header'" @close="onCloseTM"/>
  </transition>
  <div class="fx">
    <ul class="fxhead">
      <li class="home" :class="{'out': side}"><a @click="side=!side"><em></em><span>menu</span></a></li>

      <li class="in" :class="{'on': $route.meta.gnbMenu === 'charge'}"><a @click="goPageByName('charge')"><em></em><span>{{$t('front.common.deposit')}}</span></a></li>
      <li class="outs" :class="{'on': $route.meta.gnbMenu === 'exchange'}"><a @click="goPageByName('exchange')"><em></em><span>{{$t('front.common.withdrawal')}}</span></a></li>
      <li class="point" :class="{'on': $route.meta.gnbMenu === 'point'}"><a @click="goPageByName('point')"><em></em><span>{{$t('front.common.point')}}</span></a></li>
      <li class="cs" :class="{'on': $route.meta.gnbMenu === 'cs'}"><a @click="goPageByName('qnaList')"><em></em><span>{{$t('front.common.cscenter')}}</span></a></li>
    </ul>
  </div>
  <div class="subhead">
    <h1 class="logo"><a @click="goPageByName('mobileMain')"><img src="@/assets/img/coco_logo.svg" /></a></h1>
    <div class="langbtn" @click="openLang()">
      <button class="langdropbtn" >
        <template v-if="$i18n.locale === 'ko'">
          <img src="@/assets_mobile/img/korea.png" /><span class="langsp">KO</span>
        </template>
        <template v-if="$i18n.locale === 'en'">
          <img src="@/assets/img/eng.png" /><span class="langsp">EN</span>
        </template>
        <template v-if="$i18n.locale === 'vn'">
          <img src="@/assets/img/vn.svg" /><span class="langsp">VN</span>
        </template>

        <img class="langbtnb" src="@/assets_mobile/img/langbtn.png" />
      </button>
      <div class="langdropdown" v-if="isOpenLang">
        <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')">KR</a>
        <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')">EN</a>
        <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')">VN</a>
      </div>
    </div>
  </div>
  <!--a class="sideMenu" @click="side=!side" :class="{'out': side}"><em></em></a-->
  <transition name="side">
     <nav v-if="side">
       <a class="sideMenu" @click="side=!side" :class="{'in': side}"><em></em></a>
       <div class="nav-wrap">
          <div class="nav-head">
             <div class="header">
               <h1 class="logo"><a @click="goPageByNamez('mobileMain')"><img src="@/assets/img/coco_logo.svg" /></a></h1>
               <a v-if="userData" @click="goPageByNamez('msgMain')" class="mmsg"><img src="@/assets_mobile/img/message_off.png" /><span class="mgsn">{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span></a>
               <ul class="minfo" v-if="userData">
                 <li>
                    <span>{{userData.memNick}}</span>
                 </li>
                 <li>
                    <em>₩</em>
                    <span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span>
                 </li>
                 <li>
                    <span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span>
                 </li>
                 <li @click="goPageByName('point')">
                    <em>P</em>
                    <span>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}}</span>
                 </li>
               </ul>
               <div v-if="!userData">
                 <a class="mloginbtn" @click="goPageByNamez('mobileJoin')">JOIN</a>
                 <a class="mloginbtn on" @click.stop="goPageByNamez('mobileLogin')">LOGIN</a>
               </div>
             </div>
          </div>
          <ul class="nav-con">
             <li>
                 <a @click="goPageByNamez('noticeList')">
                    <img src="@/assets_mobile/img/icon_nav_notice.png" />
                    <span>{{$t('front.common.notice')}}</span>
                 </a>
             </li>
             <li>
                 <a @click="goPageByNamez('csMain')">
                    <img src="@/assets_mobile/img/icon_nav_cs.png" />
                    <span>{{$t('front.common.scscenter')}}</span>
                 </a>
             </li>
             <li>
                 <a @click="goPageByNamez('charge')">
                    <img src="@/assets_mobile/img/icon_nav_in.png" />
                    <span>{{$t('front.common.deposit')}}</span>
                 </a>
             </li>
             <li>
                 <a @click="goPageByNamez('exchange')">
                    <img src="@/assets_mobile/img/icon_nav_out.png" />
                    <span>{{$t('front.common.withdrawal')}}</span>
                 </a>
             </li>
             <li>
                 <a @click="goPageByNamez('myinfo')">
                    <img src="@/assets_mobile/img/icon_nav_my.png" />
                    <span>{{$t('front.common.mypage')}}</span>
                 </a>
             </li>
             <li>
                 <a @click="goPageByNamez('betList')">
                    <img src="@/assets_mobile/img/icon_nav_bet.png" />
                    <span>{{$t('front.mypage.betList')}}</span>
                 </a>
             </li>
          </ul>
        </div>
     </nav>
  </transition>
  <!--navi-bar>
    {{$t('front.common.mypage')}}
  </navi-bar-->
  <section class="pkbg hv">
    <div class="infowrap">
      <div class="infotopwrap">
        <div class="infotop" v-if="userData">
          <!--div class="namelv">
            <h3>
              <span class="name">{{userData.memNick}}</span> <template v-if="$i18n.locale === 'ko'">님</template>
              <em class="lv">Level. {{userData.memLevel}}</em>
              <p class="gamelv">
                <span><em>{{$t('front.gameCategory.livecasino')}}</em>LV.{{userData.casinoLevel}}</span>
                <span><em>{{$t('front.gameCategory.slot')}}</em>LV.{{userData.slotLevel}}</span>
              </p>
            </h3>
            <span class="medal" :class="getMedalClass(userData.memLevel)"><--{{userData.memLevel}}-></span>
          </div-->
          <ul class="mym">
            <li class="mycash">
               <div>
                  <em>Cash</em>
                  <span>₩ {{thousand(userData.cashAmt.toString())}}</span>
               </div>
               <a><img src="@/assets_mobile/img/icon_refresh.svg" />Refresh</a>
            </li>
            <li class="mypoint">
               <div>
                  <em>Point</em>
                  <span>P {{thousand(userData.pointAmt.toString())}}</span>
               </div>
               <a><img src="@/assets_mobile/img/icon_refresh.svg" />Refresh</a>
            </li>
            <!--li class="myg"><img src="@/assets_mobile/img/mygicon.png" /><span>{{thousand(coinAmt ? coinAmt.toString(): '0')}} P</span></li-->
          </ul>
        </div>
        <!--ul class="log">
          <template v-if="userData">
            <li class="msgbtn"><a @click="goPageByName('msgMain')"><img src="@/assets_mobile/img/note_box2.png" /><span class="mn pmn">{{thousand(userData && userData.unReadMessageCount ? userData.unReadMessageCount.toString() : '0')}}</span></a></li>
            <li class="stpbtn"><a><img src="@/assets_mobile/img/stamp2.png" /></a></li>
            <li class="logbtn"><a @click="signOut()"><img src="@/assets_mobile/img/power.png" /><span>로그아웃</span></a></li>
          </template>
          <template v-else>
            <li class="logbtn"><a @click="goPageByName('mobileLogin')"><img src="@/assets_mobile/img/power.png" /><span>로그인</span></a></li>
            <li class="logbtn"><a @click="goPageByName('mobileJoin')"><img src="@/assets_mobile/img/power.png" /><span>회원가입</span></a></li>
          </template>
          <li>
            <div class="langbtn">
              <button class="langdropbtn"><img src="@/assets_mobile/img/korea.png" /><span class="langsp">KR</span><img class="langbtnb" src="@/assets_mobile/img/langbtn.png"></button>
              <div class="langdropdown">
                <a href="#">KR</a>
                <a href="#">JP</a>
                <a href="#">CN</a>
              </div>
            </div>
          </li>
        </ul-->
      </div>
      <!--ul class="sns">
        <li><a><img src="@/assets_mobile/img/cacao.png" /><span class="br">test1234</span></a></li>
        <li><a><img src="@/assets_mobile/img/telegram.png" /><span class="bl">test1234</span></a></li>
      </ul-->
    </div>

    <ul class="menuwrap">
      <li>
         <a @click="goPageByName('betList')">
            <span>{{$t('front.mypage.betList')}}</span>
            <img src="@/assets_mobile/img/icon_bets.svg" />
         </a>
      </li>
      <li>
         <a @click="goPageByName('point')">
            <span>{{$t('front.mypage.pointList')}}</span>
            <img src="@/assets_mobile/img/icon_pointz.svg" />
         </a>
      </li>
      <li>
         <a @click="goPageByName('info')">
            <span>{{$t('front.mypage.memInfoModify')}}</span>
            <img src="@/assets_mobile/img/icon_info.svg" />
         </a>
      </li>
      <li>
         <a @click="signOut()">
            <span>{{$t('front.common.logout')}}</span>
            <img src="@/assets_mobile/img/icon_out.svg" />
         </a>
      </li>
      <li v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'">
         <a :href="'/DH1'" target="_blank">
            <img src="@/assets_mobile/img/icon_cs.svg" />
            <span>{{$t('front.mypage.exclusive')}}</span>
         </a>
      </li>
    </ul>
    <ul class="menuwrap">
      <!-- <li><a @click="onClickTM()"><img src="@/assets_mobile/img/icon_give.svg" /><span>통합머니 전환</span></a></li> -->
    </ul>
    <!--ul class="menuwrap" style="margin-bottom:20px;">
      <li><a @click="goPageByName('charge')"><img src="@/assets_mobile/img/icon_exchanges.svg" /><span>{{$t('front.mypage.depositWithdrawalList')}}</span></a></li>
      <li><a @click="goPageByName('msg')"><img src="@/assets_mobile/img/icon_msgs.svg" /><span>{{$t('front.mypage.message')}}</span><--span class="mn">{{thousand(userData && userData.unReadMessageCount ? userData.unReadMessageCount.toString() : '0')}}</span-></a></li-->
      <!--li><a @click="goPageByName('csMain')"><span>고객센터</span></a></li-->
      <!--li><a @click="goPageByName('charge')"><span>입금</span></a></li-->
      <!--li><a @click="goPageByName('exchange')"><span>출금</span></a></li-->
      <!--li><a @click="goPageByName('winlose')"><img src="@/assets_mobile/img/calculate.png" /><span>윈루즈정산</span></a></li-->
      <!--li><a><img src="@/assets_mobile/img/live_video.png" /><span>라이브정산</span></a></li-->
      <!--li><a @click="goPageByName('result')"><span>경기결과</span></a></li-->
      <!--li><a @click="goPageByName('board')"><span>게시판</span></a></li-->
      <!--li><a @click="goPageByName('coupon')"><img src="@/assets_mobile/img/coupon.png" /><span>쿠폰관리</span><span class="mn">1</span></a></li-->
      <!--li><a><img src="@/assets_mobile/img/account.png" /><span>계좌문의</span></a></li-->
    <!--/ul-->
    <!--ul class="menuwrap" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'">
      <li><a @click="goPageByName('give')"><img src="@/assets_mobile/img/icon_gives.svg" /><span>{{$t('front.mypage.memMoney')}}</span></a></li>
    </ul-->
    <ul class="menuwrap pb100" v-if="siteOption">
      <li>
         <a @click="siteOption['sns2Link']">
            <span>{{siteOption['sns2']}}<em>{{siteOption['sns2Id']}}</em></span>
            <img src="@/assets/img/kakao.svg" />
         </a>
      </li>
      <li>
         <a @click="siteOption['sns1Link']">
            <span>{{siteOption['sns1']}}<em>{{siteOption['sns1Id']}}</em></span>
            <img src="@/assets/img/telegram.svg" />
         </a>
      </li>
      <li v-if="siteOption['sns3']">
         <a @click="siteOption['sns3Link']">
            <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
            <img src="@/assets/img/zalo.svg" />
         </a>
      </li>
    </ul>
    <!--ul class="sns" v-if="siteOption">
      <li @click="siteOption['sns2Link']">
          <img src="@/assets/img/kakao.svg" />
          <span>{{siteOption['sns2']}}<em>{{siteOption['sns2Id']}}</em></span>
      </li>
      <li @click="siteOption['sns1Link']">
          <img src="@/assets/img/telegram.svg" />
          <span>{{siteOption['sns1']}}<em>{{siteOption['sns1Id']}}</em></span>
      </li>
      <li v-if="siteOption['sns3']" @click="siteOption['sns3Link']">
          <img src="@/assets/img/zalo.svg" />
          <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
      </li>
    </ul>
    <a @click="signOut()" class="mylogout mb100"><span>{{$t('front.common.logout')}}</span></a>
    <a @click="goBack" class="back"><img src="@/assets_mobile/img/back.png" /></a-->
  </section>
</template>

<script>
import { mapState } from 'vuex'
import TMmodal from '@/components/common/TotalMoney'

export default {
  name: 'myinfo',
  computed: {
    ...mapState([
      'userData',
      'siteOption'
    ])
  },
  created () {
  },
  methods: {
    async onClickTM () {
      const confirmMessage = '통합머니 전환을 진행하시겠습니까?'
      const confirmResult = await this.onConfirm(confirmMessage)
      if (confirmResult) {
        this.TMOpen = true
      }
    },
    onCloseTM () {
      this.TMOpen = false
    },
    openLang () {
      this.isOpenLang = !this.isOpenLang
    },
    onClickGo (name) {
      this.$router.push({ name: name })
    },
    onClickBack () {
      this.$router.go(-1)
    },
    getMedalClass (level) {
      if (level < 10) {
        return 'medal' + level
      } else {
        return 'medallv'
      }
    },
    goPageByNamez (name, params) {
      const query = { name: name }
      if (params) {
        query.params = params
      }
      this.$router.push(query)
      this.side = !this.side
    }
  },
  components: {
    TMmodal
  },
  data () {
    return {
      isOpenLang: false,
      TMOpen: false,
      side: false
    }
  }
}
</script>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/myinfo.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
